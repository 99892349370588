
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserSection",
  data() {
    return {
      users: {}
    };
  },
  components: {},
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch("getNFTs/getUsers").then(
        (response) => {
          this.users = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
});
