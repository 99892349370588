
import { defineComponent } from 'vue'
import AdminLayout from '@/templates/AdminLayout.vue'
import UserSection from '@/components/sections/admin/UserSection.vue'

export default defineComponent({
  name: 'AdminUser',
  components: {
    AdminLayout,
    UserSection
  },
  mounted() {
  }
})
